import { EventContextFilter } from 'common/src/models/event/eventTemplate';
import {
  deserializePaymentAuthorityRecord, PaymentAuthorityRecord,
} from 'common/src/models/event/payment';
import { deserializeResourceRef, ResourceRef } from 'common/src/models/shared';
import RequestUtils from 'common/src/utils/request';
import {
  deserializeEventTemplate, deserializeEventTicketInventory, deserializePaymentInfo,
  deserializePaymentSetupIntent, deserializeTaroPassRecord, deserializeVendor, EventTemplate,
  EventTicketInventory, PaymentInfo, PaymentSetupIntent, SelectedTicketOption, SurveyResponse,
  TaroPassRecord, Vendor,
} from '../../models/event';
import {
  deserializeEventTemplateGroup, EventTemplateGroup,
} from '../../models/event/eventTemplateGroup';
import {
  AdjustTicketOrderAction, deserializeTicketCostPreview, deserializeTicketOrder, TicketCostPreview,
  TicketOrder,
} from '../../models/event/ticket';

import { invokeAuthenticatedApi } from './helper';

export async function initiateTicketOrderRemote(
  eventTemplateId: string,
  selectedTicketOptions: SelectedTicketOption[],
  couponCodes: string[],
  utm: string,
): Promise<{
  ticketOrderId: string,
  paymentSetupIntent: PaymentSetupIntent,
}> {
  const request = {
    initiateTicketOrder: {
      eventTemplateId: eventTemplateId,
      selectedTicketOptions: selectedTicketOptions,
      couponCodes: couponCodes,
      utm: utm,
    },
  };
  const resp = await invokeAuthenticatedApi(request);

  return {
    ticketOrderId: resp.initiateTicketOrder.ticketOrderId,
    paymentSetupIntent: deserializePaymentSetupIntent(resp.initiateTicketOrder.paymentSetupIntent),
  };
}

export async function holdSeatsRemote(
  eventTemplateId: string,
  seatIds: string[],
): Promise<{
  holdToken: string,
  holdTokenExpireTs: number,
  errCode: 'seats_unavailable'
}> {
  const request = {
    holdSeats: {
      eventTemplateId: eventTemplateId,
      seatIds: seatIds,
    },
  };
  const resp = await invokeAuthenticatedApi(request);

  return {
    holdToken: resp.holdSeats.holdToken,
    holdTokenExpireTs: resp.holdSeats.holdTokenExpireTs,
    errCode: resp.holdSeats.errCode,
  };
}

export async function completeTicketOrderRemote(
  ticketOrderId: string, surveyResponses: SurveyResponse[], holdToken: string,
): Promise<TicketOrder> {
  const request = {
    completeTicketOrder: {
      ticketOrderId: ticketOrderId,
      surveyResponses: surveyResponses,
      holdToken: holdToken,
    },
  };
  const resp = await invokeAuthenticatedApi(request);

  return deserializeTicketOrder(resp.completeTicketOrder.ticketOrder);
}

export async function cancelTicketsRemote(
  ticketOrderId: string,
  ticketIds: string[],
): Promise<TicketOrder> {
  const request = {
    cancelTickets: {
      ticketOrderId: ticketOrderId,
      ticketIds: ticketIds,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  return deserializeTicketOrder(resp.cancelTickets.ticketOrder);
}

export async function bulkGetEventTemplatesRemote(eventTemplateIds: string[]): Promise<EventTemplate[]> {
  const request = {
    bulkGetEventTemplates: {
      eventTemplateIds: eventTemplateIds,
    },
  };

  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const eventTemplates = Array.of(...resp.bulkGetEventTemplates.eventTemplates);
  return eventTemplates.map((eventTemplate: any) => deserializeEventTemplate(eventTemplate));
}

export async function listEventTemplatesRemote(showPastEvent: boolean, contextFilter: EventContextFilter): Promise<EventTemplate[]> {
  const request = {
    listEventTemplates: {
      showPastEvent: showPastEvent,
      showGeneralEvents: true,
      contextFilter: contextFilter,
    },
  };

  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const eventTemplates = Array.of(...resp.listEventTemplates.eventTemplates);
  return eventTemplates.map((eventTemplate: any) => deserializeEventTemplate(eventTemplate));
}

export async function getEventTemplateGroupsRemote(): Promise<EventTemplateGroup[]> {
  const request = {
    listEventTemplateGroups: {},
  };

  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const eventTemplateGroups = Array.of(...resp.listEventTemplateGroups.eventTemplateGroups);
  return eventTemplateGroups.map((eventTemplateGroup: any) => deserializeEventTemplateGroup(eventTemplateGroup));
}

export async function getMyTicketOrdersRemote(): Promise<TicketOrder[]> {
  const request = {
    getTicketOrders: {},
  };
  const resp = await invokeAuthenticatedApi(request);
  const ticketOrders = Array.of(...resp.getTicketOrders.ticketOrders);
  return ticketOrders.map((ticketOrder: any) => deserializeTicketOrder(ticketOrder));
}

export async function previewTicketOrderCostRemote(
  eventTemplateId: string,
  selectedTicketOptions: SelectedTicketOption[],
  couponCodes: string[],
): Promise<{
  errCode: string,
  ticketCostPreviews: TicketCostPreview[],
}> {
  const request = {
    previewTicketOrderCost: {
      eventTemplateId: eventTemplateId,
      selectedTicketOptions: selectedTicketOptions,
      couponCodes: couponCodes,
      gender: 'unknown',
    },
  };
  const resp = (await RequestUtils.invokeRemoteApi(request, {})).previewTicketOrderCost;

  return {
    errCode: resp.errCode,
    ticketCostPreviews: resp.ticketCostPreviews.map(deserializeTicketCostPreview),
  };
}

export async function queryTicketOrdersRemote(
  eventTemplateId: string,
): Promise<TicketOrder[]> {
  const request = {
    queryTicketOrders: {
      eventTemplateId: eventTemplateId,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  const ticketOrders = Array.of(...resp.queryTicketOrders.ticketOrders);
  return ticketOrders.map((ticketOrder: any) =>
    deserializeTicketOrder(ticketOrder));
}

export async function queryEventTicketInventoryRemote(
  optEventTemplateId?: string,
): Promise<EventTicketInventory[]> {
  const request = {
    queryEventTicketInventory: {
      gender: 'unknown',
      eventTemplateId: optEventTemplateId,
    },
  };

  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const eventTicketInventories = Array.of(...resp.queryEventTicketInventory.eventTicketInventory);
  return eventTicketInventories.map(
    (eventTicketInventory: any) => deserializeEventTicketInventory(eventTicketInventory));
}

export async function screenTicketOrderRemote(
  ticketOrderId: string,
  screenResult: 'approved' | 'rejected',
): Promise<void> {
  const request = {
    screenTicketOrder: {
      ticketOrderId: ticketOrderId,
      screenResult: screenResult,
    },
  };
  await invokeAuthenticatedApi(request);
}

export async function adjustTicketOrderRemote(
  ticketOrderId: string,
  intendedAction: AdjustTicketOrderAction,
  refundInCents?: number,
): Promise<void> {
  const request = {
    adjustTicketOrder: {
      intendedAction: intendedAction,
      ticketOrderId: ticketOrderId,
      refundInCents: refundInCents || 0,
    },
  };
  await invokeAuthenticatedApi(request);
}

export async function checkInTicketsRemote(
  ticketOrderId: string,
  ticketIds: string[],
): Promise<void> {
  const request = {
    checkInTickets: {
      ticketOrderId: ticketOrderId,
      ticketIds: ticketIds,
    },
  };
  await invokeAuthenticatedApi(request);
}

// including the funtionality to create new event with new id or update existing event through existing id
export async function saveEventTemplateRemote(eventTemplate: EventTemplate): Promise<void> {
  const request = {
    saveEventTemplate: {
      eventTemplate: eventTemplate,
    },
  };

  await invokeAuthenticatedApi(request);
}

export async function saveEventTemplateGroupRemote(eventTemplateGroup: EventTemplateGroup): Promise<void> {
  const request = {
    saveEventTemplateGroup: {
      eventTemplateGroup: eventTemplateGroup,
    },
  };

  await invokeAuthenticatedApi(request);
}

export async function cloneEventTemplateRemote(originEventTemplateId: string, newEventTemplateId: string): Promise<void> {
  const request = {
    cloneEventTemplate: {
      originEventTemplateId: originEventTemplateId,
      newEventTemplateId: newEventTemplateId,
    },
  };

  await invokeAuthenticatedApi(request);
}

export async function saveTaroPassRemote(taroPass: TaroPassRecord): Promise<void> {
  const request = {
    saveTaroPassRecord: {
      taroPassRecord: taroPass,
    },
  };

  await invokeAuthenticatedApi(request);
}

export async function listTaroPassesRemote(): Promise<TaroPassRecord[]> {
  const request = {
    listTaroPassRecords: {},
  };
  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const taroPasses = Array.of(...resp.listTaroPassRecords.taroPassRecords);
  return taroPasses.map((taroPass: any) =>
    deserializeTaroPassRecord(taroPass));
}

export async function listVendorsRemote(): Promise<Vendor[]> {
  const request = {
    listVendors: {},
  };
  const resp = await RequestUtils.invokeRemoteApi(request, {});
  const vendors = Array.of(...resp.listVendors.vendors);
  return vendors.map((vendor: any) => deserializeVendor(vendor));
}

export async function saveVendorRemote(vendor: Vendor): Promise<void> {
  const request = {
    saveVendor: {
      vendor: vendor,
    },
  };

  await invokeAuthenticatedApi(request);
}

export async function uploadEventImageRemote(
  eventTemplateOrGroupId: string, content: string, imageType: string,
): Promise<ResourceRef> {
  const request = {
    uploadEventRef: {
      eventTemplateOrGroupId: eventTemplateOrGroupId,
      content: content,
      fileType: imageType,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  const ref = resp.uploadEventRef.ref;
  return deserializeResourceRef(ref);
}

export async function uploadTaroPassImageRemote(
  taroPassId: string, content: string, imageType: string,
): Promise<ResourceRef> {
  const request = {
    uploadTaroPassRef: {
      taroPassId: taroPassId,
      content: content,
      fileType: imageType,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  const ref = resp.uploadTaroPassRef.ref;
  return deserializeResourceRef(ref);
}

export async function uploadVendorImageRemote(
  vendorId: string, content: string, imageType: string,
): Promise<ResourceRef> {
  const request = {
    uploadVendorRef: {
      vendorId: vendorId,
      content: content,
      fileType: imageType,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  const ref = resp.uploadVendorRef.ref;
  return deserializeResourceRef(ref);
}

export async function sendSmsToUsersRemote(
  users:Array<string>, content:string,
): Promise<void> {
  const request = {
    bulkSendSms: {
      smsContent: content,
      userIds: users,
    },
  };
  await invokeAuthenticatedApi(request);
}

export async function getPaymentInfoRemote(ticketOrderId: string): Promise<PaymentInfo> {
  const request = {
    getPaymentInfo: {
      ticketOrderId: ticketOrderId,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  return deserializePaymentInfo(resp.getPaymentInfo.paymentInfo);
}

export async function getEventTemplateStandaloneToken(eventTemplateId: string): Promise<string> {
  const request = {
    getEventTemplateStandaloneToken: {
      eventTemplateId: eventTemplateId,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  return resp.getEventTemplateStandaloneToken.token;
}

export async function queryPaymentAuthorityRecords(eventTemplateId: string): Promise<PaymentAuthorityRecord[]> {
  const request = {
    queryPaymentAuthorityRecords: {
      eventTemplateId: eventTemplateId,
    },
  };
  const resp = await invokeAuthenticatedApi(request);
  const paymentAuthorityRecords = Array.of(...resp.queryPaymentAuthorityRecords.paymentAuthorityRecords);
  return paymentAuthorityRecords.map(deserializePaymentAuthorityRecord);
}
