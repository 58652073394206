import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EventContextFilter, EventTemplate, EventTemplateGroup, EventTicketInventory, PaymentSetupIntent,
  SelectedTicketOption, SurveyResponse, TaroPassRecord, TicketOrder, Vendor,
} from 'common/src/models/event';
import {
  bulkGetEventTemplatesRemote, cancelTicketsRemote, completeTicketOrderRemote,
  getEventTemplateGroupsRemote, getMyTicketOrdersRemote, holdSeatsRemote, initiateTicketOrderRemote,
  listEventTemplatesRemote, listTaroPassesRemote, listVendorsRemote, queryEventTicketInventoryRemote,
} from 'common/src/system/network/event';
import { retainRef } from 'common/src/utils/comparison';

import { AppDispatch, RootState } from '../store';
import { clearCart, setSeatMapHoldToken, setSeatMapHoldTokenExpireTs } from './cart';
import { CacheCompleteness } from './common';


export enum EventTemplatesCacheCompleteness {
  PARTIAL = 'partial',
  CURRENT = 'current',
  FULL = 'full',
}

export interface EventTemplatesCache {
  recordById: Record<string, EventTemplate>;
  completeness: EventTemplatesCacheCompleteness;
}

export interface EventTemplateGroupsCache {
  records: EventTemplateGroup[]
  completeness: CacheCompleteness;
}

export interface VendorsCache {
  records: Vendor[];
  completeness: CacheCompleteness;
}

export interface TicketOrdersCache {
  recordById: Record<string, TicketOrder>;
  recordsByEventTemplateId: Record<string, TicketOrder[]>;
  completeness: CacheCompleteness;
}

export interface EventTicketInventoriesCache {
  recordByEventTemplateId: Record<string, EventTicketInventory>;
  completeness: CacheCompleteness;
}

export interface TaroPassesCache {
  records: TaroPassRecord[];
  completeness: CacheCompleteness;
}


// ### State ###

interface EventState {
  eventTemplatesCache: EventTemplatesCache;
  eventTemplateGroupsCache: EventTemplateGroupsCache;
  vendorsCache: VendorsCache;
  ticketOrdersCache: TicketOrdersCache;
  eventTicketInventoriesCache: EventTicketInventoriesCache;
  taroPassesCache: TaroPassesCache;

  utm: string;
}

const initialState: EventState = {
  eventTemplatesCache: {
    recordById: {},
    completeness: EventTemplatesCacheCompleteness.PARTIAL,
  },
  eventTemplateGroupsCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },
  vendorsCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },
  ticketOrdersCache: {
    recordById: {},
    recordsByEventTemplateId: {},
    completeness: CacheCompleteness.PARTIAL,
  },
  eventTicketInventoriesCache: {
    recordByEventTemplateId: {},
    completeness: CacheCompleteness.PARTIAL,
  },
  taroPassesCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },

  utm: '',
};


// ### Actions ### //

export const refreshSelectEventTemplates = createAsyncThunk<
  void,
  { eventTemplateIds: string[], onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshSelectEventTemplates', async ({ eventTemplateIds, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  const eventTemplateIdsRequireRefreshing = new Set<string>();
  for (const eventTemplateId of eventTemplateIds) {
    if (onlyIfUncached && state.event.eventTemplatesCache.recordById[eventTemplateId]) {
      continue;
    }
    eventTemplateIdsRequireRefreshing.add(eventTemplateId);
  }
  if (eventTemplateIdsRequireRefreshing.size === 0) {
    return;
  }

  const eventTemplates = await bulkGetEventTemplatesRemote(Array.from(eventTemplateIdsRequireRefreshing));
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    completeness: EventTemplatesCacheCompleteness.PARTIAL,
  }));
});

export const refreshEventTemplates = createAsyncThunk<
  void,
  { includePastEvents: boolean, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplates', async ({ includePastEvents, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (includePastEvents) {
      if (state.event.eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.FULL) {
        return;
      }
    } else {
      if (state.event.eventTemplatesCache.completeness !== EventTemplatesCacheCompleteness.PARTIAL) {
        return;
      }
    }
  }

  const eventTemplates = await listEventTemplatesRemote(includePastEvents, EventContextFilter.WEB_ONLY);
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    completeness: includePastEvents ? EventTemplatesCacheCompleteness.FULL : EventTemplatesCacheCompleteness.CURRENT,
  }));
});

export const refreshEventTemplateGroups = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplateGroups', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.eventTemplateGroupsCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const eventTemplateGroups = await getEventTemplateGroupsRemote();
  dispatch(setEventTemplateGroups(eventTemplateGroups));
});

export const refreshVendors = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshVendors', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.vendorsCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const vendors = await listVendorsRemote();
  dispatch(setVendors(vendors));
});

export const refreshTicketOrders = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTicketOrders', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.ticketOrdersCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const ticketOrders = await getMyTicketOrdersRemote();
  dispatch(setTicketOrders(ticketOrders));
});

export const refreshEventTicketInventory = createAsyncThunk<
  void,
  { optEventTemplateId?: string, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTicketInventory', async ({ optEventTemplateId, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (optEventTemplateId) {
      if (state.event.eventTicketInventoriesCache.recordByEventTemplateId[optEventTemplateId]) {
        return;
      }
    } else {
      if (state.event.eventTicketInventoriesCache.completeness === CacheCompleteness.FULL) {
        return;
      }
    }
  }

  const eventTicketInventories = await queryEventTicketInventoryRemote(optEventTemplateId);
  dispatch(setEventTicketInventories({
    eventTicketInventories: eventTicketInventories,
    completeness: optEventTemplateId ? CacheCompleteness.PARTIAL : CacheCompleteness.FULL,
  }));
});

export const refreshTaroPasses = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTaroPasses', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.taroPassesCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const taroPasses = await listTaroPassesRemote();
  dispatch(setTaroPasses(taroPasses));
});


// #### Ticket purchase and management ####

export const initiateTicketOrder = createAsyncThunk<
  { ticketOrderId: string, paymentSetupIntent: PaymentSetupIntent },
  { eventTemplateId: string, selectedTicketOptions: SelectedTicketOption[], couponCodes: string[]},
  { state: RootState; dispatch: AppDispatch }
>('event/initiateTicketOrder', async ({ eventTemplateId, selectedTicketOptions, couponCodes }, { getState, dispatch }) => {
  const state = getState();
  const utm = state.event.utm;

  const resp = await initiateTicketOrderRemote(eventTemplateId, selectedTicketOptions, couponCodes, utm);

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    optEventTemplateId: eventTemplateId, onlyIfUncached: false,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);

  return resp;
});

export const holdSeats = createAsyncThunk<
  string,
  {eventTemplateId: string, seatIds: string[]},
  { dispatch: AppDispatch }
>('event/holdSeats', async ({ eventTemplateId, seatIds }, { dispatch }) => {
  const resp = await holdSeatsRemote(eventTemplateId, seatIds);
  // in order to avoid conflict, we extend 16 mins in backend, but show 15 mins here
  dispatch(setSeatMapHoldTokenExpireTs(resp.holdTokenExpireTs- 60 * 1000));
  dispatch(setSeatMapHoldToken(resp.holdToken));
  return resp.errCode;
});

export const completeTicketOrder = createAsyncThunk<
  void,
  { ticketOrderId: string, surveyResponses: SurveyResponse[] },
  { state: RootState; dispatch: AppDispatch }
>('event/completeTicketOrder', async ({ ticketOrderId, surveyResponses }, { getState, dispatch }) => {
  const state = getState();
  const holdToken = state.cart.seatMapHoldToken;
  const resultTicketOrder = await completeTicketOrderRemote(ticketOrderId, surveyResponses, holdToken);

  const ticketOrdersCopy = [...Object.values(state.event.ticketOrdersCache.recordById)];
  const idx = ticketOrdersCopy.findIndex((ticketOrder) => ticketOrder.id === resultTicketOrder.id);
  if (idx >= 0) {
    ticketOrdersCopy.splice(idx, 1, resultTicketOrder);
  } else {
    ticketOrdersCopy.push(resultTicketOrder);
  }
  dispatch(setTicketOrders(ticketOrdersCopy));

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    optEventTemplateId: resultTicketOrder.eventTemplateId, onlyIfUncached: false,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);

  dispatch(clearCart());
});

export const cancelTickets = createAsyncThunk<
  void,
  { ticketOrderId: string, ticketIds: string[] },
  { dispatch: AppDispatch }
>('event/cancelTickets', async ({ ticketOrderId, ticketIds }, { dispatch }) => {
  const resultTicketOrder = await cancelTicketsRemote(ticketOrderId, ticketIds);

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    optEventTemplateId: resultTicketOrder.eventTemplateId, onlyIfUncached: false,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);
});


// ### Slice ###

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventTemplates: (state, action: PayloadAction<{
      eventTemplates: EventTemplate[],
      completeness: EventTemplatesCacheCompleteness,
    }>) => {
      const originalEventTemplatesList: EventTemplate[] = Object.values(state.eventTemplatesCache.recordById);
      const effectiveEventTemplatesList: EventTemplate[] =
        retainRef(originalEventTemplatesList, action.payload.eventTemplates);

      const eventTemplates: Record<string, EventTemplate> = {};
      // Retain old entries
      originalEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });
      // And overwrite with new (for incremental refreshes)
      effectiveEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });

      let effectiveCacheCompleteness = state.eventTemplatesCache.completeness;
      if (action.payload.completeness === EventTemplatesCacheCompleteness.FULL) {
        effectiveCacheCompleteness = EventTemplatesCacheCompleteness.FULL;
      } else if (action.payload.completeness === EventTemplatesCacheCompleteness.CURRENT) {
        if (state.eventTemplatesCache.completeness !== EventTemplatesCacheCompleteness.FULL) {
          effectiveCacheCompleteness = EventTemplatesCacheCompleteness.CURRENT;
        }
      }
      state.eventTemplatesCache = {
        recordById: eventTemplates,
        completeness: effectiveCacheCompleteness,
      };
    },
    setEventTemplateGroups: (state, action: PayloadAction<EventTemplateGroup[]>) => {
      state.eventTemplateGroupsCache = {
        records: retainRef(state.eventTemplateGroupsCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },
    setVendors: (state, action: PayloadAction<Vendor[]>) => {
      state.vendorsCache = {
        records: retainRef(state.vendorsCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },
    setTicketOrders: (state, action: PayloadAction<TicketOrder[]>) => {
      const originalTicketOrders = Object.values(state.ticketOrdersCache.recordById);
      const effectiveTicketOrders = retainRef(originalTicketOrders, action.payload);

      const ticketOrderById: Record<string, TicketOrder> = {};
      effectiveTicketOrders.forEach((ticketOrder) => {
        ticketOrderById[ticketOrder.id] = ticketOrder;
      });

      const ticketOrdersByEventTemplateId: Record<string, TicketOrder[]> = {};
      effectiveTicketOrders.forEach((ticketOrder) => {
        if (!ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId]) {
          ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId] = [];
        }
        ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId].push(ticketOrder);
      });

      // dedup arrays such that we reuse the old array if the content is the same. This is to avoid
      // unnecessary re-rendering of components.
      Object.keys(ticketOrdersByEventTemplateId).forEach((eventTemplateId) => {
        const newTicketOrders = ticketOrdersByEventTemplateId[eventTemplateId];
        const oldTicketOrders = state.ticketOrdersCache.recordsByEventTemplateId[eventTemplateId] || [];

        const newArrJson = newTicketOrders.map((item) => JSON.stringify(item)).sort().join(',');
        const oldArrJson = oldTicketOrders.map((item) => JSON.stringify(item)).sort().join(',');
        const isEquivalent = newArrJson === oldArrJson;

        if (isEquivalent) {
          ticketOrdersByEventTemplateId[eventTemplateId] = oldTicketOrders;
        }
      });

      state.ticketOrdersCache = {
        recordById: ticketOrderById,
        recordsByEventTemplateId: ticketOrdersByEventTemplateId,
        completeness: CacheCompleteness.FULL,
      };
    },
    setEventTicketInventories: (state, action: PayloadAction<{
      eventTicketInventories: EventTicketInventory[],
      completeness: CacheCompleteness,
    }>) => {
      const originalEventTicketInventories =
        Object.values(state.eventTicketInventoriesCache.recordByEventTemplateId);
      const effectiveEventTicketInventories =
        retainRef(originalEventTicketInventories, action.payload.eventTicketInventories);

      const eventTicketInventoryByEventTemplateId: Record<string, EventTicketInventory> = {};
      // Retain old entries
      originalEventTicketInventories.forEach((eventTicketInventory) => {
        eventTicketInventoryByEventTemplateId[eventTicketInventory.eventTemplateId] = eventTicketInventory;
      });
      // And overwrite with new (for incremental refreshes)
      effectiveEventTicketInventories.forEach((eventTicketInventory) => {
        eventTicketInventoryByEventTemplateId[eventTicketInventory.eventTemplateId] = eventTicketInventory;
      });

      state.eventTicketInventoriesCache = {
        recordByEventTemplateId: eventTicketInventoryByEventTemplateId,
        completeness: state.eventTicketInventoriesCache.completeness === CacheCompleteness.FULL ?
          CacheCompleteness.FULL : action.payload.completeness,
      };
    },
    setTaroPasses: (state, action: PayloadAction<TaroPassRecord[]>) => {
      state.taroPassesCache = {
        records: retainRef(state.taroPassesCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },

    setUtm: (state, action: PayloadAction<string>) => {
      state.utm = action.payload.slice(0, 10);
    },
  },
});


// ### Selectors ### //

export const selectEventTemplatesCache = (state: RootState): EventTemplatesCache => {
  return state.event.eventTemplatesCache;
};

export const selectOptEventTemplate = (
  state: RootState, eventTemplateId: string,
): EventTemplate | null => {
  return state.event.eventTemplatesCache.recordById[eventTemplateId] || null;
};

export const selectOptEventTemplateGroup = (
  state: RootState, eventTemplateGroupId: string,
): EventTemplateGroup | null => {
  return state.event.eventTemplateGroupsCache.records
    .find((eventTemplateGroup_) => eventTemplateGroup_.id === eventTemplateGroupId) || null;
};

export const selectVendorsCache = (state: RootState): VendorsCache => {
  return state.event.vendorsCache;
};

export const selectOptVendor = (
  state: RootState, vendorId: string,
): Vendor | null => {
  return state.event.vendorsCache.records.find((vendor_) => vendor_.id === vendorId) || null;
};

export const selectTicketOrdersCache = (state: RootState): TicketOrdersCache => {
  return state.event.ticketOrdersCache;
};

export const selectTicketOrdersByTemplate = (
  state: RootState, eventTemplateId: string,
): TicketOrder[] | null => {
  return state.event.ticketOrdersCache.recordsByEventTemplateId[eventTemplateId] || null;
};

export const selectOptTicketOrder = (
  state: RootState, ticketOrderId: string,
): TicketOrder | null => {
  return state.event.ticketOrdersCache.recordById[ticketOrderId] || null;
};

export const selectEventTicketInventoriesCache = (
  state: RootState,
): EventTicketInventoriesCache => {
  return state.event.eventTicketInventoriesCache;
};

export const selectOptEventTicketInventoryByTemplate = (
  state: RootState, eventTemplateId: string,
): EventTicketInventory | null => {
  // Note that eventTicketInventory can be missing for past events even when fully loaded
  return state.event.eventTicketInventoriesCache.recordByEventTemplateId[eventTemplateId] || null;
};

export const selectTaroPassesCache = (state: RootState): TaroPassesCache => {
  return state.event.taroPassesCache;
};

export const selectTaroPass = (
  state: RootState, taroPassId: string,
): TaroPassRecord | null => {
  return state.event.taroPassesCache.records.find((taroPass_) => taroPass_.id === taroPassId) || null;
};


// ### Exports ### //

export const {
  setEventTemplates, setEventTemplateGroups, setTicketOrders, setEventTicketInventories,
  setTaroPasses, setVendors, setUtm,
} = eventSlice.actions;
export default eventSlice.reducer;
