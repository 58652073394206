import React from 'react';

import {
  EventTemplate, EventTemplateGroup, EventTicketInventory, TaroPassRecord, TicketOrder, Vendor,
} from 'common/src/models/event';
import { CacheCompleteness } from '../redux/slices/common';
import {
  EventTemplatesCacheCompleteness, refreshEventTemplateGroups, refreshEventTemplates,
  refreshEventTicketInventory, refreshSelectEventTemplates, refreshTaroPasses, refreshTicketOrders,
  refreshVendors, selectEventTemplatesCache, selectEventTicketInventoriesCache,
  selectOptEventTemplate, selectOptEventTemplateGroup, selectOptEventTicketInventoryByTemplate,
  selectOptTicketOrder, selectTaroPass, selectTaroPassesCache, selectTicketOrdersCache,
  selectVendorsCache,
} from '../redux/slices/event';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useEventTemplate = (eventTemplateId: string, forceRefresh?: boolean): EventTemplate | null => {
  const dispatch = useAppDispatch();

  const eventTemplate = useAppSelector((store) => selectOptEventTemplate(store, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshSelectEventTemplates({ eventTemplateIds: [eventTemplateId], onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateId, forceRefresh]);

  return eventTemplate;
};

const useSelectEventTemplates = (
  eventTemplateIds: string[], forceRefresh?: boolean,
): Record<string, EventTemplate | null> => {
  const dispatch = useAppDispatch();

  const eventTemplates = useAppSelector((store) => {
    const eventTemplatesMap: Record<string, EventTemplate|null> = {};
    for (const eventTemplateId of eventTemplateIds) {
      eventTemplatesMap[eventTemplateId] = selectOptEventTemplate(store, eventTemplateId);
    }
    return eventTemplatesMap;
  });
  React.useEffect(() => {
    dispatch(refreshSelectEventTemplates({ eventTemplateIds: eventTemplateIds, onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateIds, forceRefresh]);

  return eventTemplates;
};

const useEventTemplates = (includePastEvents: boolean, forceRefresh?: boolean): {
  data: Record<string, EventTemplate>,
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const eventTemplatesCache = useAppSelector(selectEventTemplatesCache);
  React.useEffect(() => {
    dispatch(refreshEventTemplates({ includePastEvents: includePastEvents, onlyIfUncached: !forceRefresh }));
  }, [dispatch, includePastEvents, forceRefresh]);

  return {
    data: eventTemplatesCache.recordById,
    ready: includePastEvents ? eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.FULL :
      eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.CURRENT,
  };
};

const useEventTemplateGroup = (eventTemplateGroupId: string, forceRefresh?: boolean): EventTemplateGroup | null => {
  const dispatch = useAppDispatch();

  const eventTemplateGroup = useAppSelector((store) => selectOptEventTemplateGroup(store, eventTemplateGroupId));
  React.useEffect(() => {
    dispatch(refreshEventTemplateGroups({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return eventTemplateGroup;
};

const useTaroPass = (taroPassId: string, forceRefresh?: boolean): TaroPassRecord | null => {
  const dispatch = useAppDispatch();

  const taroPass = useAppSelector((store) => selectTaroPass(store, taroPassId));
  React.useEffect(() => {
    dispatch(refreshTaroPasses({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return taroPass;
};


const useTaroPasses = (forceRefresh?: boolean): {
  data: TaroPassRecord[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const taroPassesCache = useAppSelector(selectTaroPassesCache);
  React.useEffect(() => {
    dispatch(refreshTaroPasses({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: taroPassesCache.records,
    ready: taroPassesCache.completeness === CacheCompleteness.FULL,
  };
};

const useVendors = (forceRefresh?: boolean): {
  data: Vendor[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const vendorsCache = useAppSelector(selectVendorsCache);
  React.useEffect(() => {
    dispatch(refreshVendors({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: vendorsCache.records,
    ready: vendorsCache.completeness === CacheCompleteness.FULL,
  };
};

const useTicketOrder = (ticketOrderId: string, forceRefresh?: boolean): TicketOrder | null => {
  const dispatch = useAppDispatch();

  const ticketOrder = useAppSelector((state) => selectOptTicketOrder(state, ticketOrderId));
  React.useEffect(() => {
    dispatch(refreshTicketOrders({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return ticketOrder;
};

const useTicketOrders = (forceRefresh?: boolean): {
  data: TicketOrder[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const ticketOrdersCache = useAppSelector(selectTicketOrdersCache);
  React.useEffect(() => {
    dispatch(refreshTicketOrders({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: Object.values(ticketOrdersCache.recordById),
    ready: ticketOrdersCache.completeness === CacheCompleteness.FULL,
  };
};

const useEventTicketInventory = (
  eventTemplateId: string, forceRefresh?: boolean,
): EventTicketInventory | null => {
  const dispatch = useAppDispatch();

  const eventTicketInventory = useAppSelector(
    (store) => selectOptEventTicketInventoryByTemplate(store, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshEventTicketInventory({
      optEventTemplateId: eventTemplateId, onlyIfUncached: !forceRefresh,
    }));
  }, [dispatch, forceRefresh, eventTemplateId]);

  return eventTicketInventory;
};

const useEventTicketInventories = (forceRefresh?: boolean): {
  data: Record<string, EventTicketInventory>,
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const eventTicketInventoriesCache = useAppSelector(
    (store) => selectEventTicketInventoriesCache(store));
  React.useEffect(() => {
    dispatch(refreshEventTicketInventory({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: eventTicketInventoriesCache.recordByEventTemplateId,
    ready: eventTicketInventoriesCache.completeness === CacheCompleteness.FULL,
  };
};

export {
  useEventTemplate, useEventTemplateGroup, useEventTemplates, useEventTicketInventories,
  useEventTicketInventory, useSelectEventTemplates, useTaroPass, useTaroPasses, useTicketOrder,
  useTicketOrders, useVendors,
};

