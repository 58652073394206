import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { TicketOrder, TicketOrderStatus } from 'common/src/models/event';
import { DefaultStyleAttrs } from '../../../constants/styles';
import { useEventTemplates, useTicketOrders } from '../../../hooks/useResource';

import { Page, Text } from 'common/src/components/base';
import MainBackground from '../../../components/MainBackground';
import TopMenu from '../../../components/TopMenu';
import TicketOrderCard from './TicketOrderCard';

export default function MyEventsScreen() {
  const { t } = useTranslation('wbevt.events');

  const { data: ticketOrders, ready: ticketOrdersReady } = useTicketOrders();
  const { data: eventTemplates, ready: eventTemplatesReady } = useEventTemplates(/* includePastEvents */ false);

  const navigate = useNavigate();

  if (!ticketOrdersReady || !eventTemplatesReady) {
    return null;
  }

  const displayedTicketOrders: TicketOrder[] = [];
  for (const ticketOrder of ticketOrders) {
    const hasEventTemplate = !!eventTemplates[ticketOrder.eventTemplateId];
    if (ticketOrder.status !== TicketOrderStatus.INIT && hasEventTemplate) {
      displayedTicketOrders.push(ticketOrder);
    }
  }

  const emptyEventView = !displayedTicketOrders.length && (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <img src={require('../../../assets/images/couple-picnic.png')} />
      <Text color='primary' fontSize={20} fontWeight={400} mt={20} mb={10} variant='italics'>{t('myEvent.emptyEventDescription')}</Text>
      <Button variant='contained' onClick={() => navigate('/')} sx={{ mb: 20 }}>{t('myEvent.registerEvent')}</Button>
    </Box>
  );

  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />

      <Box
        position='relative' mt={25}
        display={{ sm: 'inherit', md: 'flex' }} justifyContent='center' mx={DefaultStyleAttrs.responsiveMx}
      >
        <Box>
          <Text size='subtitle' variant='bold' mb={20}>{t('myEvent.myEvent')}</Text>
          <Box>
            {displayedTicketOrders
              // Sort from future to past (ts from highest to lowest)
              .sort((ticketOrder1, ticketOrder2) => ticketOrder2.initTs - ticketOrder1.initTs)
              .map((ticketOrder) => (
                <Box key={ticketOrder.id}>
                  <TicketOrderCard ticketOrderId={ticketOrder.id} eventTemplateId={ticketOrder.eventTemplateId} />
                </Box>
              ))}
          </Box>
          {emptyEventView}
        </Box>
      </Box>


      <Box display='flex' flexDirection='column' alignItems='center' my={30}>
        <Text size='paragraph' color='primary' mb={15}>support@hellotaro.com</Text>
        <Text size='paragraph' color='primary'>Copyright Taro LLC</Text>
      </Box>
    </Page>
  );
}
