import * as React from 'react';
import { Box, Container, Grid, Stack } from '@mui/material';

import { GeoStateCode, TaroPassCategoryType } from 'common/src/models/event';
import useAppSelector from '../hooks/useAppSelector';
import { useTaroPasses } from '../hooks/useResource';
import { selectStateFilterWithDefault } from '../redux/slices/setting';
import { selectMyUserInfo } from '../redux/slices/user';

import { Loading, Page, Text } from 'common/src/components/base';
import GeoStateFilter from '../components/GeoStateFilter';
import GroupButton from '../components/GroupButton';
import MainBackground from '../components/MainBackground';
import TaroPassCard from '../components/TaroPassCard';
import TaroPassRecordCard from '../components/TaroPassRecordCard';
import TopMenu from '../components/TopMenu';

export default function TaroPassScreen() {
  const { data: taroPassRecords } = useTaroPasses();
  const myUserInfo = useAppSelector(selectMyUserInfo);

  const stateFilter = useAppSelector(selectStateFilterWithDefault);

  const effectiveTaroPassRecords = (taroPassRecords || [])
    .filter((record) => !record.expiryTs || record.expiryTs > Date.now())
    .filter((record) => record.published)
    .filter((record) => record.location.state.trim().toLowerCase() === stateFilter);

  const [selectedCategory, setSelectedCategory] = React.useState(TaroPassCategoryType.UNDEFINED);
  const categories = React.useMemo(() => {
    return Array.from(new Set((effectiveTaroPassRecords || []).map((record) => record.category))).sort();
  }, [effectiveTaroPassRecords]);

  React.useEffect(() => {
    if (!categories.includes(selectedCategory) && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [selectedCategory, categories]);

  const displayedTaroPassRecords = effectiveTaroPassRecords.filter((record) => record.category === selectedCategory);


  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />

      <Box mx={30}>
        {myUserInfo && <TaroPassCard taroId={myUserInfo.user.id} />}
        <Stack direction='row' mb={10} justifyContent='space-between' alignItems='center'>
          <Text color='primary' size='title' variant='bold' mt={20} mb={10} zIndex={300}>TaroPass</Text>
          <GeoStateFilter availableStateFilters={[GeoStateCode.CA, GeoStateCode.WA]} />
        </Stack>

        <GroupButton
          items={categories.map((category) => ({
            title: category,
            key: category,
          }))}
          selected={selectedCategory}
          onChange={setSelectedCategory}
        />

        <Grid container columns={1} rowSpacing={20} mt={20}>
          {displayedTaroPassRecords
            .map((taroPass) => (
              <Grid item key={taroPass.id} sm={12} md={6} lg={4} display='flex'>
                <TaroPassRecordCard TaroPassId={taroPass.id} />
              </Grid>
            ))
          }
          {!taroPassRecords && <Container><Loading /></Container>}
        </Grid>
      </Box>

      <Box display='flex' flexDirection='column' alignItems='center' my={30}>
        <Text size='paragraph' color='primary' mb={15}>support@hellotaro.com</Text>
        <Text size='paragraph' color='primary'>Copyright Taro LLC</Text>
      </Box>
    </Page>
  );
}
