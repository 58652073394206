import { deserializeUser, ProfileStory, UserProfile } from '../../models/user';

import { invokeAuthenticatedApi } from './helper';

export async function adminBulkGetUsersRemote(userIds: string[]) {
  const request: any = {
    adminBulkGetUsers: {
      userIds: userIds,
    },
  };
  const resp = await invokeAuthenticatedApi(request);

  return new Map(
    Array
      .of(...(resp.adminBulkGetUsers.users || []))
      .map((optionalUser) => {
        return [optionalUser.userId as string, optionalUser.exists ? deserializeUser(optionalUser.user) : null];
      }),
  );
}

export async function getMyUserRemote() {
  const request: any = {
    getUser: {},
  };
  const resp = await invokeAuthenticatedApi(request);

  return {
    user: deserializeUser(resp.getUser.user),
    vipExpiryTs: resp.getUser.vipExpiryTs,
  };
}

export async function getUserContactRemote() {
  const request: any = {
    getUserContact: {},
  };
  const resp = await invokeAuthenticatedApi(request);

  return {
    email: resp.getUserContact.email as string,
    phone: resp.getUserContact.phone as string,
  };
}

export async function bulkGetUserContactsRemote(userIds: string[]) {
  const request: any = {
    bulkGetUserContacts: {
      userIds: userIds,
    },
  };
  const resp = await invokeAuthenticatedApi(request);

  return new Map(
    Array
      .of(...(resp.bulkGetUserContacts.userContacts || []))
      .map((optionalUserContact) => {
        return [optionalUserContact.userId as string, optionalUserContact.exists ? {
          email: optionalUserContact.email,
          phone: optionalUserContact.phone,
        } : null];
      }),
  );
}


export async function updateProfileRemote(myProfile: UserProfile) {
  const profileStories = myProfile.profileStories.map(
    (profileStory: ProfileStory) => ({
      type: profileStory.type,
      title: profileStory.title,
      tags: profileStory.tags,
      text: profileStory.text,
      images: profileStory.images,
    }),
  );
  const coreUserInfo = myProfile.coreUserInfo;
  let mbti = {};
  if (myProfile.mbti) {
    mbti = {
      isFilled: true,
      e: myProfile.mbti.e,
      s: myProfile.mbti.s,
      t: myProfile.mbti.t,
      j: myProfile.mbti.j,
    };
  }
  const request = {
    updateUserProfile: {
      userProfile: {
        coreUserInfo: {
          name: coreUserInfo.name,
          gender: coreUserInfo.gender,
          dob: coreUserInfo.dob,
          location: coreUserInfo.location,
          height: coreUserInfo.height,
          ethnicity: coreUserInfo.ethnicity,
        },

        weight: myProfile.weight,
        religion: myProfile.religion,
        degree: myProfile.degree,
        school: myProfile.school,
        occupation: myProfile.occupation,
        employer: myProfile.employer,
        hometown: myProfile.hometown,

        mbti: mbti,

        profileStories: profileStories,
      },
    },
  };
  await invokeAuthenticatedApi(request);
}

export async function updateUserEmailRemote(updatedEmail: string) {
  const request: any = {
    updateUserContact: {
      email: updatedEmail,
    },
  };
  await invokeAuthenticatedApi(request);
}
