import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import LoginIcon from '@mui/icons-material/Login';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { AuthContext } from 'common/src/context/auth';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import { DefaultStyleAttrs } from '../constants/styles';

import LogoSvg from 'common/src/assets/svgs/logoSvg';
import MyEventsSvg from 'common/src/assets/svgs/MyEventsSvg';
import TaroPassSvg from 'common/src/assets/svgs/TaroPassSvg';
import { Text } from 'common/src/components/base';
interface IProps {
  addMarginX?: boolean
  disableHomeClick?: boolean
}
const TopMenu: React.FC<IProps> = ({ addMarginX, disableHomeClick }) => {
  const navigate = useNavigate();

  const { authStatus, logout } = React.useContext(AuthContext);
  const { t, i18n } = useTranslation('wbevt.home');
  const isEn = i18n.language === 'en';
  const isLoggedIn = authStatus === 'loggedIn';

  const { windowWidth } = useWindowDimensions();
  const isSmallScreen = windowWidth < 400;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = React.useState<'main' | 'lang'>('main');

  const isMainMenuOpen = !!menuAnchorEl && menuType === 'main';
  const isLangMenuOpen = !!menuAnchorEl && menuType === 'lang';
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuType('main');
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleToggleMenu = (type: 'main' | 'lang') => {
    setMenuType(type);
  };

  const handleNavigateToTaroPass = React.useCallback(() => {
    navigate('/taropass');
  }, [navigate]);

  const handleNavigateToMyEvents = React.useCallback(() => {
    navigate('/myevents');
  }, [navigate]);

  return (
    <Stack direction='row'
      left={0} right={0} color={Colors.PRIMARY_BACKGROUND} position='relative'
      py={10}
      justifyContent='space-between' alignItems='center'
      mx={addMarginX ? DefaultStyleAttrs.responsiveMx : 0}
    >
      <IconButton disabled={!!disableHomeClick} onClick={() => navigate('/')}>
        <LogoSvg size={35} />
      </IconButton>

      <Stack direction='row'>
        {isLoggedIn && !isSmallScreen && (
          <Button
            onClick={handleNavigateToMyEvents} variant='text'
            startIcon={<MyEventsSvg color={Colors.PRIMARY} />}
            sx={{ mr: 6 }}
          >
            {t('topMenu.myEvent')}
          </Button>
        )}
        {!isSmallScreen && (
          <Button
            onClick={handleNavigateToTaroPass} variant='text'
            startIcon={<TaroPassSvg color={Colors.PRIMARY} />}
            sx={{ textTransform: 'none' }}
          >
            TARO Pass
          </Button>
        )}
        <IconButton onClick={handleOpenMenu}>
          {isSmallScreen ?
            <MenuOutlinedIcon sx={{ color: Colors.PRIMARY, fontSize: 30 }} /> :
            <AccountCircleIcon sx={{ color: isLoggedIn ? Colors.PRIMARY : Colors.DISABLED, fontSize: 30 }} />
          }
        </IconButton>
      </Stack>

      <Menu
        anchorEl={menuAnchorEl}
        open={isMainMenuOpen}
        onClose={handleCloseMenu}
      >
        {!isLoggedIn && (
          <MenuItem onClick={() => {
            navigate('/auth');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <LoginIcon sx={{ mr: 10 }} />
            {t('topMenu.login')}
          </MenuItem>
        )}

        {isLoggedIn && isSmallScreen && (
          <MenuItem onClick={() => {
            navigate('/myevents');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <Stack direction='row' alignItems='center'>
              <Box sx={{ height: 20, width: 20, ml: 2, mr: 12 }}>
                <MyEventsSvg color={'black'} />
              </Box>
              {t('topMenu.myEvent')}
            </Stack>
          </MenuItem>
        )}

        {isSmallScreen && (
          <MenuItem onClick={() => {
            navigate('/taropass');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <Stack direction='row' alignItems='center'>
              <Box sx={{ height: 20, width: 20, ml: 4, mr: 10 }}>
                <TaroPassSvg color={'black'} />
              </Box>
                TARO Pass
            </Stack>
          </MenuItem>
        )}

        {isLoggedIn && (
          <MenuItem onClick={() => {
            navigate('/profile');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <AccountCircleOutlinedIcon sx={{ mr: 10 }} />
            {t('topMenu.personalInfo')}
          </MenuItem>
        )}

        <MenuItem onClick={() => {
          handleToggleMenu('lang');
        }} sx={{ py: 10, pr: 70 }}>
          <TranslateIcon sx={{ mr: 10 }} />
          {t('topMenu.language')}
        </MenuItem>

        {isLoggedIn && (
          <MenuItem onClick={() => {
            logout();
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <LogoutOutlinedIcon sx={{ mr: 10 }} />
            {t('topMenu.logout')}
          </MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={menuAnchorEl}
        open={isLangMenuOpen}
        onClose={handleCloseMenu}
      >
        <Box display='flex' sx={{ py: 5, pl: 5, pr: 15 }} justifyItems='center' alignItems='center' alignContent='center'>
          <IconButton onClick={() => handleToggleMenu('main')}>
            <ArrowBackIcon />
          </IconButton>
          <Text size='subtitle'>{t('topMenu.chooseLang')}</Text>
        </Box>
        <Divider />
        <MenuItem onClick={() => {
          i18n.changeLanguage('en');
          handleCloseMenu();
        }} sx={{ py: 10, pr: 70 }}>
          <CheckIcon fontSize='small' sx={{ mr: 10, opacity: isEn ? 1 : 0 }} />
          <Text size='paragraph'>English</Text>
        </MenuItem>
        <MenuItem onClick={() => {
          i18n.changeLanguage('zh');
          handleCloseMenu();
        }} sx={{ py: 10, pr: 70 }}>
          <CheckIcon fontSize='small' sx={{ mr: 10, opacity: isEn ? 0 : 1 }} />
          <Text size='paragraph'>中文</Text>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default TopMenu;
