import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid } from '@mui/material';

import Env from 'common/src/env';
import { WILDCARD_STATE } from 'common/src/models/event/eventTemplate';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplates, useEventTicketInventories } from '../../hooks/useResource';
import { EventTemplatesCacheCompleteness, selectEventTemplatesCache } from '../../redux/slices/event';
import { selectStateFilterWithDefault } from '../../redux/slices/setting';

import { Loading } from 'common/src/components/base';
import EventListingCard from '../../components/EventListingCard';
import SearchBar from './SearchBar';

interface IProps {
  eventType: 'current' | 'past';
}
const EventsTab: React.FC<IProps> = ({ eventType }) => {
  const { t } = useTranslation('wbevt.home');

  const { data: allEventTemplates } = useEventTemplates(/* includePastEvents */ eventType === 'past');
  const { data: eventTicketInventories } = useEventTicketInventories();

  const eventTemplatesCache = useAppSelector(selectEventTemplatesCache);
  const stateFilter = useAppSelector(selectStateFilterWithDefault);
  const [searchTerm, setSearchTerm] = React.useState('');

  const allEventTemplatesForTab = React.useMemo(() => {
    const filteredEventTemplates = Object.values(allEventTemplates)
      .filter((eventTemplate) =>
        !eventTemplate.archived && (Env.shouldShowWIPFeatures() || eventTemplate.published),
      )
      .filter((eventTemplate_) => eventTemplate_.visible)
      .filter((eventTemplate) => {
        if (eventTemplate.location.state.trim().toLowerCase() === stateFilter) {
          return true;
        }
        if (eventTemplate.additionalAdvertisedStates.includes(WILDCARD_STATE)) {
          return true;
        }
        if (eventTemplate.additionalAdvertisedStates.some((state) => state.trim().toLowerCase() === stateFilter)) {
          return true;
        }
        return false;
      });

    const now = Date.now();
    if (eventType === 'current') {
      return filteredEventTemplates
        .filter((eventTemplate) => now <= eventTemplate.payload.hostedEventPayload.registrationToTs)
        .sort((e1, e2) => {
          // Sort from most to least recent (ts from lowest to highest)
          const e1Registerable = now > e1.payload.hostedEventPayload.registrationFromTs;
          const e2Registerable = now > e2.payload.hostedEventPayload.registrationFromTs;
          if (e1Registerable && e2Registerable || !e1Registerable && !e2Registerable) {
            return e1.eventFromTs - e2.eventFromTs;
          } else {
            return e1Registerable ? -1 : 1;
          }
        });
    } else {
      return filteredEventTemplates
        .filter((eventTemplate) => now > eventTemplate.payload.hostedEventPayload.registrationToTs)
      // Sort from most to least recent (ts from highest to lowest)
        .sort((e1, e2) => e2.eventFromTs - e1.eventFromTs);
    }
  }, [allEventTemplates, eventType, stateFilter]);

  const displayedEventTemplatesForTab = React.useMemo(() => {
    return allEventTemplatesForTab
      .filter((eventTemplate) => {
        if (eventTemplate.name.toLowerCase().includes(searchTerm)) {
          return true;
        }
        if (eventTemplate.intro.toLowerCase().includes(searchTerm)) {
          return true;
        }
        if (eventTemplate.description.toLowerCase().includes(searchTerm)) {
          return true;
        }
        return false;
      });
  }, [allEventTemplatesForTab, searchTerm]);

  if (eventType === 'current' && eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.PARTIAL ||
      eventType === 'past' && eventTemplatesCache.completeness !== EventTemplatesCacheCompleteness.FULL) {
    // Still loading
    return <Container><Loading /></Container>;
  }

  return (
    <Box>
      <SearchBar
        label={t(eventType === 'current' ? 'landing.searchCurrentEvent' : 'landing.searchPastEvent')}
        onSearchTermChange={setSearchTerm}
      />

      <Grid container columnSpacing={20} rowSpacing={20}>
        {
          displayedEventTemplatesForTab.map((eventTemplate) => (
            <Grid item key={eventTemplate.id} sm={12} md={6} lg={4} display='flex'>
              <EventListingCard
                eventTemplateId={eventTemplate.id}
                eventTicketInventory={eventType === 'current' && eventTicketInventories[eventTemplate.id] || null}
              />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
};

export default EventsTab;
