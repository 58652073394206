import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AccessTime as AccessTimeIcon, PlaceOutlined as PlaceOutlinedIcon,
} from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardMedia, Stack } from '@mui/material';

import { Colors, ComponentStyles } from 'common/src/constants';
import { EventTicketInventory, extractEventTemplateMetadata } from 'common/src/models/event';
import { EVENT_TAGS, getEventDateLabelWithMask } from 'common/src/models/event/eventTemplate';
import useAppDispatch from '../hooks/useAppDispatch';
import useAppSelector from '../hooks/useAppSelector';
import { useEventTemplate } from '../hooks/useResource';
import { refreshEventTicketInventory, selectOptEventTicketInventoryByTemplate } from '../redux/slices/event';

import { Text } from 'common/src/components/base';

interface IProps {
  eventTemplateId: string
  // if undefined, we will automatically load it. If null, this is an explicit signal to not load it.
  eventTicketInventory?: EventTicketInventory | null
  nameOverride?: string
  hideImg?: boolean
}
const EventListingCard: React.FC<IProps> = ({ eventTemplateId, eventTicketInventory, nameOverride, hideImg }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventTemplate = useEventTemplate(eventTemplateId);


  const storedEventTicketInventory = useAppSelector(
    (store) => selectOptEventTicketInventoryByTemplate(store, eventTemplateId));
  React.useEffect(() => {
    if (eventTicketInventory === null) {
      return;
    }
    (async () => {
      await dispatch(refreshEventTicketInventory({
        optEventTemplateId: eventTemplateId, onlyIfUncached: true,
      }));
    })();
  }, [dispatch, eventTemplateId, eventTicketInventory]);

  // Note, do not override the eventTicketInventory if it is null.
  const effectiveEventTicketInventory = eventTicketInventory !== undefined ?
    eventTicketInventory :
    storedEventTicketInventory;

  const { t: tCommon, i18n } = useTranslation('wbevt.common');
  const { t: tEvents } = useTranslation('wbevt.events');

  const handlePressCard = React.useCallback(() => {
    navigate(`/e/${eventTemplateId}`);
  }, [navigate, eventTemplateId]);

  if (!eventTemplate) {
    return null;
  }

  const effectiveTags = [];
  if (eventTemplate.singlesEvent) {
    effectiveTags.push(tCommon('common.singleEvent'));
  }
  eventTemplate.tags.forEach((tag) => {
    effectiveTags.push(i18n.language === 'zh' ? tag: EVENT_TAGS[tag]);
  });

  const meta = extractEventTemplateMetadata(eventTemplate, effectiveEventTicketInventory);
  const dateLabel = getEventDateLabelWithMask(eventTemplate, /* showTime */ false);
  return (
    <Card sx={{
      display: 'flex', flexDirection: 'column', /* ensure the card is stretched vertically */
      flexGrow: 1, /* ensure the card is stretched horizontally */
    }}>
      <CardActionArea onClick={handlePressCard} sx={{
        bgcolor: Colors.DEFAULT_BACKGROUND,
        display: 'flex', flexDirection: 'column', flexGrow: 1,
        justifyContent: 'flex-start', alignItems: 'flex-start',
      }}>
        {
          hideImg ?
            null : (
              <Box bgcolor='#E2E2E2' height={185} width='100%'>
                {
                  eventTemplate.media.downloadUrl ? (
                    <CardMedia
                      component='img'
                      image={eventTemplate.media.downloadUrl}
                      alt={eventTemplate.media.id}
                      loading='lazy'
                      sx={{ height: 185 }}
                    />
                  ) : (
                    <Text size='subtitle' color='primary' centered pt={85}>IMG Placeholder</Text>
                  )
                }
                {meta.isNew &&
                  <Box
                    position='absolute'
                    top={5} left={5}
                    py={2} px={5}
                    bgcolor={Colors.DEFAULT_BACKGROUND}
                    border={1}
                    borderRadius={20}
                    borderColor={Colors.ACTION}
                  >
                    <Text size='paragraph' fontSize={13}>🎉 New</Text>
                  </Box>
                }
                {!!meta.badgeKey && (
                  <Box
                    position='absolute'
                    top={meta.isNew? 33: 5} left={5}
                    py={2} px={5}
                    bgcolor={Colors.DEFAULT_BACKGROUND}
                    border={1}
                    borderRadius={20}
                    borderColor={Colors.ACTION}
                  >
                    <Text size='paragraph'>{tEvents(meta.badgeKey)}</Text>
                  </Box>
                )}
              </Box>
            )
        }

        <CardContent>
          <Box px={5}>
            <Text size='subtitle' variant='bold' mb={3} maxLines={2}>{nameOverride || eventTemplate.name}</Text>
            {effectiveTags.length > 0 && (
              <Box>
                {effectiveTags.map((tag) => (
                  <Box display='inline-block' key={tag} bgcolor={Colors.PRIMARY_BACKGROUND} borderRadius={1} px={7} py={3} mb={3} mr={4}>
                    <Text size='note' color='system'>{tag}</Text>
                  </Box>
                ))}
              </Box>
            )}
            <Text size='paragraph' variant='italics' mb={10} mt={2} maxLines={2}>{eventTemplate.intro}</Text>

            {!!dateLabel && (
              <Stack direction='row' alignItems='center'>
                <AccessTimeIcon
                  color='primary'
                  sx={{ fontSize: ComponentStyles.text.subtitle.fontSize, mr: 7 }}
                />
                <Text color='primary' size='paragraph' maxLines={1}>
                  {dateLabel}
                </Text>
              </Stack>
            )}

            <Stack direction='row' alignItems='center'>
              <PlaceOutlinedIcon
                color='primary'
                sx={{ fontSize: ComponentStyles.text.subtitle.fontSize, mr: 7 }}
              />
              <Text color='primary' size='paragraph' maxLines={1}>
                {eventTemplate.location.city}
              </Text>
            </Stack>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default EventListingCard;
